import {
  Box,
  generateUtilityClasses,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { ButterImage } from '../butter-image/butter-image';

const classes = generateUtilityClasses('DigitalSectionCard', [
  'butterImage',
  'description',
  'styledBox',
  'title',
]);

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.styledBox}`]: {
    height: 250,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: 350,
    },
  },
  [`& .${classes.butterImage}`]: {
    objectFit: 'contain',
  },
  [`& .${classes.description}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    marginTop: theme.spacing(3),
  },
}));

export type DigitalSectionCardProps = {
  alt: string;
  description: string;
  image: string;
  title: string;
};

export function DigitalSectionCard({
  alt,
  description,
  image,
  title,
}: DigitalSectionCardProps) {
  return (
    <StyledGrid item xs={6}>
      <Box className={classes.styledBox}>
        <ButterImage
          className={classes.butterImage}
          src={image}
          layout="fill"
          alt={alt}
        />
      </Box>
      <Typography className={`${classes.title} g-typography-h3`} component="h2">
        {title}
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
    </StyledGrid>
  );
}
