import { Box, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('PartnersSection', [
  'description',
  'findButton',
  'image',
  'searchLabel',
  'searchDescription',
  'textSectionWrapper',
  'textSection',
  'title',
]);

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  [`& .${classes.description}`]: {
    margin: theme.spacing(2, 0),
  },
  [`& .${classes.findButton}`]: {
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.searchDescription}`]: {
    marginTop: theme.spacing(3),
  },
  [`& .${classes.searchLabel}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.textSection}`]: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(14),
    },
  },
  [`& .${classes.textSectionWrapper}`]: {
    paddingTop: theme.spacing(10),
  },
  [`& .${classes.title}`]: {
    marginTop: theme.spacing(2),
  },
}));
