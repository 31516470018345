import { AddressCoordinates } from './types';

export const LATITUDE_CORRECTION_FACTOR_MOBILE = 0.005;
export const LATITUDE_CORRECTION_FACTOR_DESKTOP = 0.006;

export const getAddressLatLng = async (
  address: string,
): Promise<AddressCoordinates | null> => {
  if (window.google) {
    const geocoder = new window.google.maps.Geocoder();
    const response = await geocoder.geocode({ address: address });
    const addressRaw = response.results[0];
    if (!addressRaw) {
      return null;
    }
    const addressGeometry = {
      lat: addressRaw.geometry.location.lat(),
      lng: addressRaw.geometry.location.lng(),
    };
    return addressGeometry;
  }
  return null;
};
