export const mapStyles = [
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];

export const mapContainerStyle = {
  width: '100%',
  height: '100%',
};
