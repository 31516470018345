import theme from '@grimme/theme';
import { Skeleton, useMediaQuery } from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { mapContainerStyle, mapStyles } from './map-styles';
import { AddressCoordinates, SingleMarkerMapProps } from './types';
import {
  getAddressLatLng,
  LATITUDE_CORRECTION_FACTOR_DESKTOP,
  LATITUDE_CORRECTION_FACTOR_MOBILE,
} from './utils';
import { environment } from '@/environment';

export const SingleMarkerMap = (props: SingleMarkerMapProps) => {
  const { address, hasOverlayingCard = false } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: environment.googleMapsApiKey,
  });
  const [addressCoordinates, setAddressCoordinates] =
    useState<AddressCoordinates | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  //when there's a card overlying the map, we need to adjust the map's
  //centre position so that the marker isn't hidden.
  const latCorrectionFactor = !hasOverlayingCard
    ? 0
    : isMobile
      ? LATITUDE_CORRECTION_FACTOR_MOBILE
      : LATITUDE_CORRECTION_FACTOR_DESKTOP;

  const onMapLoad = useCallback(
    async (map: google.maps.Map) => {
      const addressCoordinates = await getAddressLatLng(address);
      if (addressCoordinates) {
        setAddressCoordinates(addressCoordinates);
        const mapCenter = {
          lat: addressCoordinates?.lat - latCorrectionFactor,
          lng: addressCoordinates?.lng,
        };
        mapRef.current = map;
        mapRef.current?.setCenter(mapCenter);
      }
    },
    [address, latCorrectionFactor],
  );

  useEffect(() => {
    if (!mapRef.current) return;
    onMapLoad(mapRef.current);
  }, [onMapLoad]);

  const mapOptions = {
    disableDefaultUI: true,
    ...(isMobile
      ? {
          gestureHandling: 'greedy',
        }
      : { gestureHandling: 'cooperative' }),
    styles: mapStyles,
  };

  if (!isLoaded) {
    return (
      <Skeleton
        data-testid="single-marker-map-skeleton"
        height="100%"
        width="100%"
        variant="rectangular"
      />
    );
  }

  return (
    <GoogleMap
      data-testid="single-marker-map"
      mapContainerStyle={mapContainerStyle}
      onLoad={onMapLoad}
      options={mapOptions}
      zoom={14}
    >
      {addressCoordinates && (
        <Marker
          clickable={false}
          data-testid={`marker-position-lat:${addressCoordinates?.lat}-lng:${addressCoordinates?.lng}`}
          position={addressCoordinates}
        />
      )}
    </GoogleMap>
  );
};
