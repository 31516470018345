import { TitleDivider } from '@grimme/components';
import { Box, Container, Grid, Typography } from '@mui/material';
import Link from 'next/link';
import { useContactsPerRegion } from '../grimme-data-per-region/use-contacts-per-region';
import { Root, classes } from './styles';
import { LandingPageData, ServicePageData } from '@/lib/butter-types';
import { ButterImage } from '../butter-image/butter-image';
import Button from '@grimme/ui-components/button';

export type ContactSectionProps =
  | LandingPageData['contact_section']
  | ServicePageData['contact_section'];

export function ContactSection(props: ContactSectionProps) {
  const { title, contact_methods } = props;
  const contactsPerRegion = useContactsPerRegion();
  const localRepresentation = contactsPerRegion?.local_representation;

  return (
    <Root>
      <Container>
        <Typography
          component="h1"
          className={`${classes.title} g-typography-h2`}
        >
          {title}
        </Typography>
        <TitleDivider variant="center" />
        <Grid container spacing={2} className={classes.methodsContainer}>
          {/* TODO: Refactor this render logic in a better way */}
          {contact_methods?.map((method) => {
            const linkLocation: string =
              method.region_based_contact && localRepresentation
                ? localRepresentation[method.region_based_contact]
                : method.link_location;
            const linkProps = {
              link: linkLocation,
              text: method.link_text || linkLocation,
              target: '',
            };
            switch (method.contact_method) {
              case 'e-mail':
                linkProps.link = `mailto:${linkLocation}`;
                linkProps.target = '_blank';
                if (method.region_based_contact && localRepresentation) {
                  linkProps.text = localRepresentation.email;
                }
                break;
              case 'telephone':
                linkProps.link = `tel:${linkLocation}`;
                linkProps.target = '_blank';
                break;
              case 'whatsapp':
                linkProps.link = `https://wa.me/${linkLocation}`;
                linkProps.target = '_blank';
                break;
            }
            return (
              <Grid
                container
                item
                xs={6}
                md={3}
                key={method.contact_method}
                className={classes.methodItem}
              >
                <Box className={classes.imageBox}>
                  <ButterImage
                    className={classes.butterImage}
                    src={method.image}
                    alt={method.image_alt}
                    layout="fill"
                  />
                </Box>

                <Typography className={`${classes.ctaText} g-typography-h5`}>
                  {method.cta_text || method.contact_method}
                </Typography>
                <Box className={classes.link}>
                  <Button variant="text" asChild>
                    <Link href={linkProps.link} target={linkProps.target}>
                      {linkProps.text}
                    </Link>
                  </Button>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Root>
  );
}
