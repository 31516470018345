import classNames from 'classnames';
import { SingleMarkerMap } from './single-marker-map';
import { SingleMarkerMapProps } from './types';
import { useUserCentricsConsent } from '@grimme/gdap';
import Image from 'next/image';

type ConsentedSingleMarkerMapProps = SingleMarkerMapProps & {
  fallbackImgContainerClassNames?: string;
};

export const ConsentedSingleMarkerMap = ({
  fallbackImgContainerClassNames,
  ...mapMakerProps
}: ConsentedSingleMarkerMapProps) => {
  const isGoogleMapsConsentGiven = useUserCentricsConsent('Google Maps');

  if (isGoogleMapsConsentGiven) {
    return <SingleMarkerMap {...mapMakerProps} />;
  }

  return (
    <div
      className={classNames(
        'relative w-full h-full z-0',
        fallbackImgContainerClassNames,
      )}
    >
      <div className="absolute inset-0 bg-grey-500/50 backdrop-blur-sm z-10" />
      <Image
        src="/assets/img/map-placeholder-for-consent.png"
        alt=""
        height={759}
        width={1132}
        className="object-cover w-full h-full z-0"
      />
    </div>
  );
};
