import { Box, generateUtilityClasses, styled, Tab } from '@mui/material';
import MuiTabs from '@mui/material/Tabs';
import * as React from 'react';

const classes = generateUtilityClasses('Tabs', [
  'tab',
  'tabsIndicator',
  'tabsWrapper',
]);

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '100%',
  [`& .${classes.tabsWrapper}`]: {
    display: 'inline-block',
    maxWidth: '100vw',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(-2),
    position: 'relative',
    '&::after': {
      content: '""',
      background: theme.palette.grey[200],
      borderRadius: '50px',
      bottom: '0',
      height: '2px',
      left: '0',
      right: '0',
      position: 'absolute',
      zIndex: 3,
    },
  },
  [`& .${classes.tab}`]: {
    ...(theme.typography.h4 as object),
    color: theme.palette.grey[300],
    padding: theme.spacing(1, 4),
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
    },
    '&.Mui-selected': {
      color: theme.palette.grey[500],
    },
  },
  [`& .${classes.tabsIndicator}`]: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.grey[500],
      borderRadius: '50px',
      height: '2px',
      zIndex: 8,
    },
  },
}));

export type TabsProps = {
  categories: { name: string }[];
  value: number;
  setValue: (value: number) => void;
};

export const Tabs = (props: TabsProps) => {
  const { categories, setValue, value } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledBox>
      <Box className={classes.tabsWrapper}>
        <MuiTabs
          variant="scrollable"
          orientation="horizontal"
          visibleScrollbar={false}
          aria-label="basic tabs"
          className={classes.tabsIndicator}
          onChange={handleChange}
          value={value}
        >
          {(categories || []).map((category) => (
            <Tab
              className={classes.tab}
              key={category.name}
              label={category.name}
            ></Tab>
          ))}
        </MuiTabs>
      </Box>
    </StyledBox>
  );
};
