import { Box, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('PartnersCard', [
  'button',
  'card',
  'link',
  'root',
]);

export const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: 560,
    position: 'relative',
    div: {
      borderRadius: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      height: 460,
      marginTop: theme.spacing(5),
    },
  },
  [`& .${classes.card}`]: {
    bottom: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    left: theme.spacing(2),
    position: 'absolute',
    padding: theme.spacing(3),
    right: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      padding: theme.spacing(2),
      right: theme.spacing(1),
    },
  },
  [`& .${classes.link}`]: {
    color: 'inherit',
    display: 'block',
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.button}`]: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
