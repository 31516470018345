import { faAngleRight, faClose } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '@grimme/components';
// import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { IconButton, InputAdornment } from '@mui/material';
import { useMemo, useState } from 'react';
import { StyledButton, StyledForm, StyledTextField, classes } from './styles';
import { useGeolocation } from '@/hooks';
import { DEFAULT_LOCALE } from '@/utils';
export interface LocationSearchFormProps {
  action?: string;
  countriesList: { country_code: string; country_name: string }[];
  countrySelectLabel: string;
  submitLabel: string;
  zipCodeTextfieldLabel: string;
}

export function LocationSearchForm(props: LocationSearchFormProps) {
  const {
    action,
    countriesList = [],
    countrySelectLabel,
    submitLabel,
    zipCodeTextfieldLabel,
  } = props;
  const geolocation = useGeolocation();
  // const insights = useAppInsightsContext();
  const [formData, setFormData] = useState({
    countryCode: geolocation.countryCode || DEFAULT_LOCALE,
    zipCode: '',
  });

  /*
    NOTE: This handleOnSubmit was never working because the onSubmit was never passed
    to the component anywhere.
  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!onSubmit) {
      return;
    }
    onSubmit && onSubmit(formData);
    insights?.trackEvent({
      name: "Search Partner",
      properties: {
        country: formData.countryCode,
        zipCode: formData.zipCode,
      },
    });
  };
  */

  const setFormValue = (key: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const contriesListAsOptions = useMemo(
    () =>
      countriesList.map((country) => ({
        label: (
          <>
            {country.country_code} <span>- {country.country_name}</span>
          </>
        ),
        value: country.country_code,
      })),
    [countriesList],
  );

  const EndAdornement = () => (
    <InputAdornment position="end">
      <IconButton
        aria-label="clear zip code input"
        edge="end"
        onClick={() => setFormValue('zipCode', '')}
        onMouseDown={(e) => e.preventDefault()}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
    </InputAdornment>
  );

  return (
    <StyledForm action={action && action} title="location-search-form">
      <Select
        data-testid="country-select"
        className={classes.countrySelect}
        label={countrySelectLabel}
        name="countryCode"
        onChange={(e) => setFormValue('countryCode', e.target.value as string)}
        options={contriesListAsOptions}
        value={formData.countryCode}
      />

      <StyledTextField
        InputProps={{
          endAdornment: formData.zipCode ? <EndAdornement /> : null,
        }}
        inputProps={{ maxLength: 8 }}
        label={zipCodeTextfieldLabel}
        name="zipCode"
        onChange={(e) => setFormValue('zipCode', e.target.value)}
        value={formData.zipCode}
      />

      <StyledButton
        endIcon={<FontAwesomeIcon icon={faAngleRight} />}
        type="submit"
        variant="primary"
      >
        {submitLabel}
      </StyledButton>
    </StyledForm>
  );
}
