import { generateUtilityClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const classes = generateUtilityClasses('ContactSection', [
  'butterImage',
  'ctaText',
  'imageBox',
  'link',
  'methodItem',
  'methodsContainer',
  'title',
]);

export const Root = styled('section')(({ theme }) => ({
  textAlign: 'center',
  [`& .${classes.butterImage}`]: {
    objectFit: 'contain',
    objectPosition: 'center bottom',
  },
  [`& .${classes.ctaText}`]: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  [`& .${classes.imageBox}`]: {
    height: 88,
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 103,
    },
  },
  [`& .${classes.link}`]: {
    //Solution to align all links in the same line,
    //regardless of the length of the cta text
    display: 'table-row',
    height: 44,
    a: {
      margin: 'auto',
    },
  },
  [`& .${classes.methodItem}`]: {
    display: 'table',
    hyphens: 'auto',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
  [`& .${classes.methodsContainer}`]: {
    marginTop: theme.spacing(6),
    rowGap: theme.spacing(8),
  },
  [`& .${classes.title}`]: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(1),
    },
  },
}));
